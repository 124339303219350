<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy } from 'svelte';
  import QRCode from 'qrcode';

  import flightdeck from '../../../js/services/flightdeck';
  import logger from '../../../js/logger';
  import { Priority } from '../../../js/const';
  import { rightToLeft } from '../../../js/stores';
  import { timeoutBlocked } from '../../../js/stores';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import Button from '../Button.svelte';
  import LoadingAnimation from '../LoadingAnimation.svelte';
  import ModalBase from '../ModalBase.svelte';
  import PaymentType from '../PaymentType.svelte';
  import X from '../Icons/X.svelte';

  import failIcon from '../../../svg/xCircle.svg';
  import checkCircle from '../../../svg/checkCircle.svg';
  import { appReport, ApplicationStep, EventStatus, EventType, USER_ACTIONS } from '../../../js/appReport';

  export let controller;
  export let handleContinue = () => {};
  export let handleDeclined = () => {};

  export let showContinueButton = true; 
  export let showModal = false;
  export let showSuccess = false;
  export let showFail = false;

  let isLoading = true;
  let QRCodeDataSrc = null;
  let timeout = null;

  /**
   * Display a QR code that takes the user to Amadeus Pay
   * for payment of the current amount payable
   */
  function showQRCode() {
    try {
      logger.info('inside Payment.svelte.. showQRCode called..');
      controller.prepareCheckoutForm().then((url) => {
        logger.info('inside Payment.svelte.. inside then() of controller.prepareCheckoutForm(), url - ', url, ' now calling timout 5 sec');
        timeout = setTimeout(() => {
          QRCode.toString(
            controller.useCheckoutFormTargetURL(),
            { type: 'svg' },
            function (error, dataUrl) {
              if (error) {
                logger.warn(`showQRCode Error: ${error.message}`);
              }
              flightdeck.paymentInProgress();
              QRCodeDataSrc = dataUrl;
              timeoutBlocked.set(true);
              isLoading = false;
            }
          );
        }, 5000);
      });
    } catch(e) {
      logger.warn('inside showQRCode().. exception occured... e = ', e);
    }
  }

  /**
   * Wrapper function for the handleDecline call back. Necessary to capture the fact that the user declined within the payment modal.
   */
  function handleDeclinedLocal()
  {
    appReport.updateStepWithAction(ApplicationStep.EXCESS_BAGGAGE_PAYMENT, EventType.STEP_IN_PROGRESS, EventStatus.SUCCESS, USER_ACTIONS.EXCESS_BAGGAGE_PAYMENT_MODAL_DECLINED)
    
    // Stop polling the OLD status if they declined.
    controller.cancelPolling()
    
    handleDeclined()
  }

  $: if (showModal) {
    showQRCode();
  }

  onDestroy(() => {
    timeoutBlocked.set(false);
    clearTimeout(timeout);
  });
</script>

<ModalBase
  heading={$_('payment.heading')}
  priority={Priority.LOW}
  bind:showModal
>
  {#if isLoading}
    <div class="py-4 mt-30">
      <LoadingAnimation class="w-152 mx-auto" />
    </div>
  {:else}
    <div class="px-4">
      <div class="mb-10 {$rightToLeft ? 'text-right' : 'text-left'}">
        <p class="text-2xl mb-2">{controller.getPaymentItemHeading()}</p>
        <p class="uppercase text-2xl font-AltisMedium mb-3 text-gold-100">
          {controller.getPaymentItemPurchases()}
        </p>
      </div>

      <div class="border-b flex items-end justify-end mb-8">
        <div class="flex flex-col items-end mb-4">
          <p class="uppercase text-2xl font-AltisMedium text-gold-100">
            {controller.calculatePaymentTotal()}
          </p>

          <p class="text-lg">{$_('payment.nonRefundable')}</p>
        </div>
      </div>

      <div class="text-center">
        <PaymentType class="mb-3" />

        <p class="text-2xl mb-5">{$_('payment.userMobileText')}</p>

        <p class="text-2xl mb-7">{$_('payment.scanCode')}</p>

        <div class="flex justify-center mb-8 -mt-4">
          <div class="w-68">
            {@html QRCodeDataSrc}
          </div>
        </div>

        <div class="flex justify-center mb-6">
          <div class="flex-none w-2/5 border-t border-gold-100" />
          <p
            class="flex-shrink text-center text-4xl font-AltisMedium uppercase
            text-gold-100 w-1/5 -mt-6"
          >
            {$_('app.or')}
          </p>
          <div class="flex-none w-2/5 border-t border-gold-100" />
        </div>
      </div>
    </div>

    <div dir="rtl" class="bg-white flex justify-center mb-8 mt-2">
      <Button
        icon={X}
        intent={VoiceIntent.END}
        on:click={() => {
          showModal = false;
          handleDeclinedLocal();
        }}
      />
    </div>
  {/if}
</ModalBase>

<ModalBase
  heading={$_('modal.paymentSuccess.heading')}
  textLine1={$_('modal.paymentSuccess.textLine1')}
  icon={checkCircle}
  iconClass="text-palmGreen"
  bind:showModal={showSuccess}
>
  {#if showContinueButton}
    <div class="w-full mx-auto mt-auto">
      <Button on:click={handleContinue} intent={VoiceIntent.CONTINUE}>
        {$_('app.continue')}
      </Button>
    </div>
  {/if}
</ModalBase>

<ModalBase
  heading={$_('modal.paymentFail.heading')}
  textLine1={$_('modal.paymentFail.textLine1')}
  icon={failIcon}
  bind:showModal={showFail}
>
  <div class="w-full mx-auto mt-auto">
    <Button
      on:click={() => {
        showModal = true;
        showFail = false;
      }}
      intent={VoiceIntent.CONTINUE}
    >
      {$_('app.continue')}
    </Button>
  </div>
</ModalBase>
