<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';

  import {
    applicationFlow,
    booking,
    currentBag,
    currentBagIndex,
    currentPassenger,
    getRemainingTotalBags,
    getRemainingTotalWeight,
    headPassenger,
  } from '../../js/stores';
  import {
    ApplicationFlow,
    FARE_CODE_MAP,
    WeightUnit,
  } from '../../js/const';
  import logger from '../../js/logger';

  import checkedBaggage from '../../svg/checkedBaggage.svg';

  export let isPcBaggage = null;
  
  let isBusinessClass = null;
  let isPorterBagDrop = null;
  let isHybrid = null;
  let bagPieceWeight = 0;
  let currentBagNumber;

  let isExcessBag = false;
  
  $: remainingTotalBagsValue = getRemainingTotalBags(isPorterBagDrop);
  $: remainingTotalWeightValue = getRemainingTotalWeight(isPorterBagDrop);

  onMount(() => {
    logger.info('on mount of verification info');

      // Return to bag drop case
      if (booking.totalNumberOfActivatedBagTags() > 0) {
        isExcessBag = booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags() <= 0;          
      } else {
        // Non-return to bag drop case
        let index = isPorterBagDrop || isHybrid ? $currentBagIndex + 1 : $currentBagIndex; 
        isExcessBag = booking.totalCombinedAllowance() - index < 0;
      }


    isBusinessClass = FARE_CODE_MAP['Business'].includes(
      booking.getBookingCode(get(headPassenger)),
    );
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    isPorterBagDrop = get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP;
    isHybrid = get(applicationFlow) === ApplicationFlow.HYBRID;

    const bookingPieceWeights = booking.getPieceWeights();
    const pieceWeightIndexDecrement = isPorterBagDrop || isHybrid ? 0 : 1;
    const pieceWeightIndex = get(currentBagIndex) - pieceWeightIndexDecrement;
    bagPieceWeight = bookingPieceWeights[pieceWeightIndex] > 0 ? bookingPieceWeights[pieceWeightIndex] : bookingPieceWeights[0];

    logger.debug(
      `VerificationInfo - bagPieceWeight: ${bagPieceWeight}` +
        `, bookingPieceWeights: ${JSON.stringify(bookingPieceWeights)}` +
        `, currentBagIndex: ${get(currentBagIndex)}` +
        `, pieceWeightIndex: ${pieceWeightIndex}.`,
    );

    // PORTER_BAG_DROP index starts with 0, and SELF_SERVICE starts with 1
    let currentBag = get(currentBagIndex) + (isPorterBagDrop || isHybrid ? 1 : 0);
    let totalBagsAllowed = currentPassenger.bagTagsTotal();

    // these checks are for edge cases where currentBag is undefined or 0
    if (!currentBag || currentBag === 0) {
      currentBag = 1;
    }

    // these checks are for edge cases where totalBagsAllowed is undefined or 0
    if (!totalBagsAllowed || totalBagsAllowed === 0) {
      totalBagsAllowed = 1;
    }

    if (currentBag <= totalBagsAllowed) {
      currentBagNumber = currentBag;
    } else {
      currentBagNumber = totalBagsAllowed;
    }
  });
</script>

<div class="absolute bottom-24 left-0 right-0">
  <div class="border-t border-gold-100 mx-18 pb-10">
    <div class="flex items-center mb-8">
      <div class="w-1/2">
        <h3 class="font-bold">
          {currentBagNumber}
          {$_('app.of')}
          <span class="text-gold-100">{currentPassenger.bagTagsTotal()}</span>
          {$_('app.bags')}
        </h3>
      </div>
      <div class="w-1/2">
        <div class="w-20">
          {@html checkedBaggage}
        </div>
      </div>
    </div>

    <div class="flex mb-6 text-1.375">
      <div class="w-1/2">
        <p>{$_('placeBag.baggageWeight')}</p>
      </div>
      <div class="w-1/2">
        <p class="font-bold px-2">
          {$currentBag.weight || 0}
          {$_(`app.${WeightUnit}`)}
        </p>
      </div>
    </div>


    <div class="flex mb-6 text-1.375 text-palmGreen">
      
      <div class="w-1/2">
        <p>{$_('placeBag.remainingAllowance')}</p>
      </div>      
        
      <div class="w-1/2">
        <p class="font-bold px-2">
          {#if isPcBaggage}
            {bagPieceWeight}
          {:else}
            {remainingTotalWeightValue}
          {/if}

          {$_(`app.${WeightUnit}`)}          
        </p>
      </div>
    </div>

    {#if !isPcBaggage}
      <div class="flex text-1.375">
        <div class="w-1/2">
          <p>{$_('placeBag.baggageAllowance')}</p>
        </div>
        <div class="w-1/2">
          <p class="font-bold px-2">
            {booking.totalCombinedAllowance()}
            {$_(`app.${WeightUnit}`)}
          </p>
        </div>
      </div>
    {/if}
  </div>
</div>
