/** Store state relating to user's booking. */
import { _, locale } from 'svelte-i18n';
import { derived, get } from 'svelte/store';
import mrzParser from 'mrz';

import {
  dateTimeToDate,
  isObjEmpty,
  mapFareCode,
  randomString,  
  stripLeadingZeroes,
  dateTimeToTime,
  timeFormat  
} from '../utils';
import { baseStationCode } from '../stores/config';
import { checkInPassengersManager } from '../stores/checkInPassengers';
import { getBaggageRoutePassengerId } from './helpers/getBaggageRoutePassengerId';
import cities from '../data/miscellaneous/cities.json';
import { DisplayedSegmentsFilter } from './helpers/displayedSegmentsFilter';
import { infants } from './infants';
import logger from '../../js/logger';
import {
  AllowanceType,
  FARE_CODE_MAP,
  MILLISECONDS_PER_SECOND,
  PieceUnit,
  PieceUnitP,
  TransactionInitiator,
  WeightUnit,
} from '../const';
import { passengerMrzManager } from './passengerMrzs';
import { resettable } from './extensions/resettable';

const INFANT_PASSENGER_TYPE = 'INFANT'; // for Infant Passenger
const SECONDS_PER_MINUTE = 60; // seconds
const translate = get(_);

/** Build a map of translated city names to be used for translation. */
let citiesMap = new Map();
cities.forEach((item) => {
  const key = Object.keys(item)[0];
  const value = item[key];

  if (key && value) {
    citiesMap.set(key.toLowerCase(), value);
  }
});

/** Determine if a date is a valid Date object. */
function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

/** Convert ISO 8601 date string into dd Mmm YYYY string */
function ddMmmYYYY(date, targetLocale) {
  if (!isValidDate(date)) {
    return date;
  }

  const formatter = new Intl.DateTimeFormat(targetLocale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return formatter.format(date);
}

/** Convert ISO 8601 date string into HH:MM 24-time string */
function time(date) {
  if (!date) {
    return date;
  }

  return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
}

/** Reverse the timezone offset due to the local machine. */
function compensateForTimezone(dateString) {
  const parsed = new Date(dateString);
  return new Date(
    parsed.getTime() +
    parsed.getTimezoneOffset() *
    SECONDS_PER_MINUTE *
    MILLISECONDS_PER_SECOND,
  );
}

/** Various inputs. */
export const pnr = resettable('');
export const mrz = resettable([]);
export const bioMrz = resettable('');
export const boardingPass = resettable('');

/** How the transaction was initiated. */
export const userInitiationMethod = resettable(null);

export const transactionHasStarted = derived(
  userInitiationMethod,
  ($userInitiationMethod) => Boolean($userInitiationMethod),
);

export const booking = {
  ...resettable({}),
  ...{
    /** Get passenger by passengerIndex. */
    passengerByIndex(passengerIndex) {
      const { passengers } = get(this);
      return (
        (passengers || []).find(
          (passenger) => passenger.passengerIndex === passengerIndex,
        ) || {}
      );
    },

    /** Get infant passenger by passengerIndex. */
    infantByIndex(passengerIndex) {
      const infantsPassengers = get(infants);
      return (
        (infantsPassengers || []).find(
          (infant) => infant.passengerIndex === passengerIndex,
        ) || {}
      );
    },

    /** Number of passengers in the booking, including infants. */
    passengerCount() {
      return this.getPassengersIncludingInfants().length;
    },

    /** Get a passenger by PassengerID. */
    passengerByID(passengerID) {
      const { passengers } = get(this);
      return (
        (passengers || []).find(
          (passenger) => passenger.passengerID === passengerID,
        ) || {}
      );
    },

    /** Get a Parent passenger by infantPassengerID. */
    getPassengerByInfantId(infantPassengerID) {
      const { passengers } = get(this);
      return (
        (passengers || []).find(
          (passenger) => passenger.infantPassengerID === infantPassengerID,
        ) || {}
      );
    },

    /** Filter out infants from the passengers array. */
    filterInfants(passengers) {
      return passengers.filter(
        (passenger) => passenger.passengerType !== INFANT_PASSENGER_TYPE,
      );
    },

    /**
     *  Return an array combining all passengers (adults) and infants
     *
     * @returns {object[]} Array of Passenger object from Switchbaord
     */
    getPassengersIncludingInfants() {
      return (get(this).passengers || []).reduce(
        (allPassengers, passenger) => {
          allPassengers.push(passenger);
          if (booking.passengerHasInfant(passenger)) {
            allPassengers.push(booking.getInfantForPassenger(passenger));
          }
          return allPassengers;
        },
        [],
      );
    },

    /**
     * Return an array of the Switchboard passengers in the booking.
     *
     * After the passport scan screen, this array will be culled of
     * passengers who are not present at the bag drop.
     *
     * @returns {object[]} The passengers.
     */
    getPassengersExcludingInfants() {
      return get(this).passengers;
    },

    /** Get a Infant passenger by PassengerID. */
    //getInfantByID(passengerID) {
    getInfantByID(infantPassengerID) { // for amadeus Adult Infant Passenger ID = Infant Passenger ID
      const infantsPassengers = get(infants);
      return (
        (infantsPassengers || []).find(
          (infant) => infant.passengerID === infantPassengerID,
        ) || {}
      );
    },

    getInfantForPassenger(passenger) {
      return passenger.passengerType === INFANT_PASSENGER_TYPE
        ? {}
        //: this.getInfantByID(passenger.passengerID);
        : this.getInfantByID(passenger.infantPassengerID); // for amadeus Adult Infant Passenger ID = Infant Passenger ID
    },

    /** Determine if a passenger has an infant. */
    passengerHasInfant(passenger) {
      return !isObjEmpty(this.getInfantForPassenger(passenger));
    },

    /** Get Frequent Flyer object for a passenger. */
    getFrequentFlyerInformation(passenger) {
      const { frequentFlyerInformation } = passenger;
      return frequentFlyerInformation || {};
    },

    /**
     * Get Frequent Flyer Number from passenger
     * @param {object} passenger - as Object
     */
    getFrequentFlyerNumber(passenger) {
      const { number } = this.getFrequentFlyerInformation(passenger);
      return number;
    },

    /**
     * Get Frequent Flyer Tier Name from passenger
     * @param {object} passenger - as Object
     */
    getFrequentFlyerTierName(passenger) {
      const { tierName } = this.getFrequentFlyerInformation(passenger);
      return tierName;
    },

    /**
     * Get Frequent Flyer short name,
     * @param {object} passenger - as Object
     */
    getFrequentFlyerShortText(passenger) {
      const { shortText } = this.getFrequentFlyerInformation(passenger);
      return shortText;
    },

    /**
     * Get reactive baggage unit.
     *
     * @returns {string} - A reactive baggage unit.
     */
    getReactiveBaggageUnit() {
      return derived(locale, () => booking.getBaggageUnit());
    },

    /**
     * Get reactive destination city.
     *
     * @param {string} segment - The segment.
     * @returns {string} - A reactive destination city.
     */
    getReactiveDestinationCity(segment = null) {
      return derived(locale, () => booking.getDestinationCity(segment));
    },

    /**
     * Get reactive origin city.
     *
     * @param {string} segment - The segment.
     * @returns {string} - A reactive origin city.
     */
    getReactiveOriginCity(segment = null) {
      return derived(locale, () => booking.getOriginCity(segment));
    },

    /**
     * Get the translated passenger title,
     * @param {object} passenger - as Object
     */
    getTranslatedTitle(passenger) {
      let title = this.getTitle(passenger);

      title
        ? (title = translate(`title.${title.toLowerCase()}`))
        : (title = '');

      return title;
    },

    /**
     * Get a passenger by passport number.
     *
     * Will retrieve a passenger from either adults or infants.
     *
     * @param {string} passportNumber - The passport number.
     * @returns {object} The passenger object from SwitchBoard.
     */
    passengerMatchingDocSPassportNumber(passportNumber) {
      const { passengers } = get(this);
      const adultsAndInfants = (passengers || []).concat(get(infants));
      return (
        adultsAndInfants.find(
          (passenger) =>
            this.passengerDocSPassportNumber(passenger) === passportNumber,
        ) || {}
      );
    },

    // this function is to check if the passport number matches any other 
    // passenger's passport number 
    passportNumberMatchesAnyOtherPassenger(passenger, passportNumber) {
      const { passengers } = get(this);
      const adultsAndInfants = (passengers || []).concat(get(infants));
      let matchFound = false;
      adultsAndInfants.forEach((p) => {
        if (p?.passengerID !== passenger?.passengerID) {
          if (p?.passportNumber === passportNumber) {
            matchFound = true;
          }
        }
      })

      return matchFound;
    },

    /**
     * Is this passportNumber a match for a passengers docS?
     *
     * @param {string} passportNumber
     * @returns {boolean}
     */
    isMatchToPassengerDocS(passportNumber) {
      return !isObjEmpty(
        this.passengerMatchingDocSPassportNumber(passportNumber),
      );
    },

    /**
     * Get the switchboard segment object for a given flightNumber.
     *
     * @param {string} flightNumber
     * @returns {object} Switchboard Segment object.
     */
    getSegment(flightNumber = null) {
      flightNumber = flightNumber || get(this).flightNumber;

      return (
        get(this).segments.find(
          (segment) => segment.flightNumber == flightNumber,
        ) || {}
      );
    },

    /**
     * Get the translated city.
     *
     * @param {string} city
     * @param {string} code
     * @returns {string} The translated city name.
     */
    getTranslatedCity(city, code = null) {
      const language = get(locale);

      city = city || '';

      if (language === 'en') {
        // Do nothing.
      } else if (citiesMap.has(city.toLowerCase())) {
        const cityNameObj = citiesMap.get(city.toLowerCase());
        cityNameObj[language]
          ? (city = cityNameObj[language])
          : logger.info(
            `A translation does not exist for '${city}' in '${language}'.`,
          );
      } else if (code && citiesMap.has(code.toLowerCase())) {
        // try to get city name by Airport Code like LHR AAL
        // the cities.json file provided by Etihad only has
        // arabic translation with airport code
        const codeObject = citiesMap.get(code.toLowerCase());
        if (codeObject) {
          codeObject[language]
            ? (city = codeObject[language])
            : console.log('No translation found using City code');
        }
      } else {
        logger.info(`Search term '${city}' not defined.`);
      }

      return city;
    },

    /**
     * Get the displayed origin city.
     *
     * Defaults to using the airport code if the city name isn't available.
     *
     * @param {string} segment
     * @returns {string} The city name.
     */
    getOriginCity(segment = null) {
      if (!segment) {
        segment = this.getSegment(get(this).flightNumber);
      }

      const { departureCity, departureCode } = segment;
      return (
        this.getTranslatedCity(departureCity, departureCode) || departureCode
      );
    },

    /**
     * Get the displayed destination city.
     *
     * Defaults to using the airport code if the city name isn't available.
     *
     * @param {string} segment
     * @returns {string} The city name.
     */
    getDestinationCity(segment = null) {
      if (!segment) {
        segment = this.getSegment(get(this).flightNumber);
      }

      const { arrivalCity, arrivalCode } = segment;
      return this.getTranslatedCity(arrivalCity, arrivalCode) || arrivalCode;
    },

    /**
     * Get passengers boarding pass barcode.
     *
     * Automatically overwrites the seat number if there has been a
     * seat change.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The raw boarding pass BCBP or an empty string.
     */
    getBarcode(passenger, flightNumber = null) {
      let barcode = '';
   
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
              barcode = segment.barcode;
            }
          });
        }
      }
      return barcode;
    },

    /**
     * Get passengers boarding pass Zone number.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The Zone.
     */
    getZoneNumber(passenger, flightNumber = null) {
      let zone = '';

      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
            zone = segment.zone;
            }
          });
        }
      }
  
      return zone;
    },

    /**
     * Get flight airline name.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The airline names. @example "ETIHAD AIRWAYS".
     */
    getAirlineName(flightNumber = null) {
      const airlineName = this.getSegment(flightNumber).airlineName || '';
      return airlineName;
    },

    /**
     * Get flight airline code.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The airline names. @example "EY".
     */
    getAirlineCode(flightNumber = null) {
      const airlineCode = this.getSegment(flightNumber).airlineCode || '';
      return airlineCode;
    },

    /**
     * Get flight arrival datetime.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The arrival date and time. @example "2021-04-13T06:10:00".
     */
    getArrivalDateTime(flightNumber = null) {
      const arrivalDateTime =
        this.getSegment(flightNumber).arrivalDateTime || '';
      return arrivalDateTime;
    },

    /**
     * Get flight departure date.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The departure date. @example "2021-04-13".
     */
    getDepartureDate(flightNumber = null) {
      const departureDate =
        this.getSegment(flightNumber).departureDateTime || '';
      return dateTimeToDate(departureDate);
    },

    /**
     * Get flight departure estimated departure time.
     * example 2022-03-23 02:55:00 PM
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The departure time. @example "10:00".
     */
    getEstimatedDepartureTime(flightNumber = null) {
      const currentBooking = get(this);
      if (flightNumber === currentBooking.flightNumber) {
        if (currentBooking) {
          const departureEstimatedTime = currentBooking.departureTime
            .replace('AM', '')
            .replace('PM', '');
          const timeSetting = currentBooking.departureTime.substring(5); // get the AM or PM
          const depatureEstimatedDateTime =
            currentBooking.departureDate +
            ' ' +
            departureEstimatedTime +
            ':00 ' +
            timeSetting;
          return timeFormat(depatureEstimatedDateTime, false);
        }
      }

      const departureDateTime =
        dateTimeToTime(this.getSegment(flightNumber).departureDateTime) || '';
      return departureDateTime;
    },

    /**
     * Get flight departure datetime.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The departure date and time. @example "2021-04-13T06:10:00".
     */
    getDepartureDateTime(flightNumber = null) {
      const departureDateTime =
        this.getSegment(flightNumber).departureDateTime || '';
      return departureDateTime;
    },

    /**
     * Get flight arrival airport code.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The arrival code. @example "AUH".
     */
    getArrivalCode(flightNumber = null) {
      const arrivalCode = this.getSegment(flightNumber).arrivalCode || '';
      return arrivalCode;
    },

    /**
     * Get flight departure airport code.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string} The departure code. @example "AUH".
     */
    getDepartureCode(flightNumber = null) {
      const departureCode = this.getSegment(flightNumber).departureCode || '';
      return departureCode;
    },

    /**
     * Get passengers departure terminal.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The departure terminal.
     */
    getDepartureTerminal(passenger, flightNumber = null) {
      let departureTerminal = 'N/A';

      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
              departureTerminal = segment.departureTerminal
            }
          });
        }
      }
      
      return departureTerminal;
    },

    /**
     * Get passengers boarding gate for a flight.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The boarding gate.
     */
    getDepartureGate(passenger, flightNumber = null) {
      let departureGate = 'N/A';

      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
              departureGate = segment.departureGate
            }
          });
        }
      }

      return departureGate;
    },

    /**
     * Get the passengers booking class.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The booking class. Will use the check-in response,
     *                  the value from the booking retrieval, or an empty
     *                  string.
     */
    getBookingCode(passenger, flightNumber = null) {
      let bookingClass = 'N/A';
      
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }
      if (!flightNumber) {
        if (passenger) {
          return passenger.bookingClass;
        }
      }

      if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
        passenger.segments.map((segment) => {
          if (segment.flightNumber === flightNumber) {
            bookingClass = segment.bookingClass
          }
        });
      }

      return bookingClass;
    },

    /**
     * Method to get a booking class from passenger object.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {string} booking class of the passenger
     */
    getPassengerBookingClass(passenger) {
      return passenger ? passenger.bookingClass : null;
    },

    /**
     * Get the passengers booking class.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The deck value. Will use the check-in response,clear
     *                  the value from the booking retrieval, or an empty
     *                  string.
     */
    getDeck(passenger, flightNumber = null) {
      let deck = 'N/A';

      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
              deck = segment.deck
            }
          });
        }
      }

      return deck;
    },

    /**
     * Get class of service (as defined by FARE_CODE_MAP)..
     *
     * @param {object} passenger - Switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The class of service, eg. 'Economy', 'Business'.
     */
    getClassOfService(passenger, flightNumber = null) {
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }
      const bookingCode = this.getBookingCode(passenger);

      return mapFareCode(bookingCode);
    },

    /**
     * Get passenger title based on gender (eg. MR, MS).
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {string} The passengers title (or null).
     */
    getTitle(passenger) {
      return passenger.title || null;
    },

    /**
     * Get the passengers sequence number.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The sequence number. Will use the check-in response,
     *                  the value from the booking retrieval, or an empty
     *                  string.
     */
    getSequenceNumber(passenger, flightNumber = null) {
      let sequenceNumber = 'N/A';
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      if (flightNumber) {
        if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
          passenger.segments.map((segment) => {
            if (segment.flightNumber === flightNumber) {
              sequenceNumber = segment.sequenceNumber
            }
          });
        }
      }

      return sequenceNumber;
    },

    /**
     * Get the passengers boarding time..
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The boarding time. Uses checkin/reprint data
     *                  or empty string.
     */
    getBoardingTime(passenger, flightNumber = null) {
    
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      let boardingTime = 'N/A';
      if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
        passenger.segments.map((segment) => {
          if (segment.flightNumber === flightNumber) {
            boardingTime = segment.boardingTime
          }
        });
      }
      
      return boardingTime;
    },

    /**
     * Get the the seats of all passengers.
     *
     * @param {string} flightNumber - The flight number.
     * @returns {string[]} - Seat numbers of all passengers
     */
    getPassengerSeats(flightNumber = null) {
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }

      return this.getPassengersIncludingInfants().map((passenger) =>
        booking.getSeatNumber(passenger, flightNumber),
      );
    },

    /**
     * Get the passengers ticket number.
     *
     * @param {object} passenger - The switchboard passenger object.
     * @param {string} flightNumber - The flight number as a string.
     *                                Will default to the first leg of
     *                                the booking.
     * @returns {string} The ticket nmber. Will use the check-in response,
     *                  the value from the booking retrieval, or an empty
     *                  string.
     */
    getTicketNumber(passenger) {
      return passenger.ticketNumber || '';
    },

    /** Get passenger passport number. */
    passengerDocSPassportNumber(passenger) {
      const { passportNumber } = this.parsePassengerDocString(passenger);
      return passportNumber;
    },

    /**
     * Determine if passenger matches a passportNumber.
     *
     * Relies on the DocS string.
     *
     * @param {object} passenger - A Passenger object from switchboard.
     * @param {string} passportNumber - A passport number.
     * @returns {boolean}
     */
    isPassengerDocSMatchingPassportNumber(passenger, passportNumber) {
      return this.passengerDocSPassportNumber(passenger) === passportNumber;
    },

    /**
     * Determine if this is a single passenger transaction.
     *
     * @param {boolean} allowInfant - A passenger that includes an infant.
     * @returns {boolean}
     */
    isSinglePassengerTransaction(allowInfant = false) {
      const hasNoInfant = get(infants).length === 0;
      return get(this).passengers.length === 1 && (allowInfant || hasNoInfant);
    },

    /** Passengers total weight allowance. */
    passengerTotalAllowance(passenger) {
      return this.passengerAllowanceByType(passenger, false);
    },

    /**
     * Returns passengers who were checked in when the booking was retrieved.
     *
     * @returns {object[]} Array of Switchboard Passenger objects.
     */
    checkedInPassengers() {
      return this.getPassengersIncludingInfants().filter(
        (passenger) => passenger.isCheckedIn,
      );
    },

    /**
     * Returns passengers who require an ADC check
     *
     * @returns {object[]} Array of Switchboard Passenger objects.
     */
    passengersRequiringADCCheck() {
      return this.getPassengersIncludingInfants().filter(
        (passenger) => !(passenger.isADCCheckComplete),
      );
    },

    /**
     * Returns a boolean if any pax are found with ADC checks required
     * 
     * @returns {boolean} 
     */
    hasPassengersRequiringADCCheck() {
      return this.passengersRequiringADCCheck().length > 0;
    },

    /**
     * Returns passengers not checked in when the booking was retrieved.
     *
     * @returns {object[]} Array of Switchboard Passenger object.
     */
    uncheckedInPassengers() {
      return this.getPassengersIncludingInfants().filter(
        (passenger) => !passenger.isCheckedIn,
      );
    },

    /**
     * Does the booking have a passenger who has a guest tier?
     *
     * @returns {boolean}
     * @returns {object[]} passengers - A list of passengers
     */
    hasGuestTier(passengers = null) {
      passengers = passengers || this.getPassengersIncludingInfants();
      return passengers.some(
        (passenger) => (passenger.frequentFlyerInformation || {}).tierName,
      );
    },

    /**
     * Does the booking have any passengers who are already checked in?
     *
     * @returns {boolean}
     */
    hasCheckedInPassengers() {
      return Boolean(this.checkedInPassengers().length);
    },

    /**
     * Does the booking have passengers who are not checked in?
     *
     * @returns {boolean}
     */
    hasUncheckedInPassengers() {
      return this.uncheckedInPassengers().length > 0;
    },

    /**
     * Does booking have a mixture of checked in and non checked in passengers.
     *
     * @returns {boolean}
     */
    hasCheckedAndNonCheckedPassengers() {
      const isCheckedAndNonChecked =
        this.getPassengersIncludingInfants().some(
          (passenger) => passenger.isCheckedIn,
        ) &&
        this.getPassengersIncludingInfants().some(
          (passenger) => !passenger.isCheckedIn,
        );
      return isCheckedAndNonChecked;
    },

    /**
     * Passengers baggage allowance by type.
     *
     * Pass baggageType as false to ignore type checking,
     * which means the total allowance is returned.
     *
     * @param {object} passenger - A passenger object from switchboard.
     * @param {boolean|String} baggageType - The baggage type, or false..
     * @returns {number} The baggage allowance.
     */
    passengerAllowanceByType(passenger, baggageType) {
      const { baggage } = passenger;
      const ignoreType = !baggageType;

      return (baggage || [])
        .filter(
          (baggageItem) => ignoreType || baggageItem.type === baggageType,
        )
        .reduce((allowance, baggageItem) => {
          return (allowance += Number(baggageItem.allowance) || 0);
        }, 0);
    },

    /** Adjust the date strings to use local time. */
    compensateDateTimes() {
      function invokeForString(arg, func) {
        return typeof arg === 'string' ? func(arg) : arg;
      }

      const data = get(this);
      data.arrivalDateTime = invokeForString(
        data.arrivalDateTime,
        compensateForTimezone,
      );
      data.departureDateTime = invokeForString(
        data.departureDateTime,
        compensateForTimezone,
      );
      this.set(data);
    },

    setPNRInBooking(inputPNR) {
      const data = get(this);
      data.bookingReference = inputPNR;
    },

    /** Update the pnr store using the one from the booking, if there is one. */
    setPNR(inputPNR = null) {
      if (inputPNR) {
        pnr.set(inputPNR);
      } else {
        const data = get(this);
        if (
          'bookingReference' in data &&
          data.bookingReference &&
          !Boolean(get(pnr))
        ) {
          pnr.set(data.bookingReference);
          logger.info(`PNR: ${data.bookingReference}`);
        }
      }
    },

    /**
     * Get the baggage.
     *
     * @returns {object[]} Array of baggage.
     */
    getBaggage() {
      const baggage =
        (this.getPassengersIncludingInfants() || []).flatMap(
          (passenger) => passenger.baggage || [],
        ) || [];

      return baggage;
    },

    /**
     * Get the baggage unit, i.e. 'kg', 'pc'.
     *
     * @returns {string}
     */
    getBaggageUnit() {
      const baggageUnit =
        (this.getBaggage().find((baggage) => baggage.unit) || {}).unit ||
        WeightUnit;

      return translate(`app.${baggageUnit.toLowerCase()}`);
    },

    isPieceBaggage() {
      return this.getBaggageUnit() === PieceUnit;
    },

    getBaggageUnitWithOutTranslation() {
      const baggageUnit =
        (this.getBaggage().find((baggage) => baggage.unit) || {}).unit ||
        WeightUnit;

      return baggageUnit.toLowerCase();
    },

    isPieceBaggageWithOutTranslation() {
      return this.getBaggageUnitWithOutTranslation() === PieceUnit || this.getBaggageUnitWithOutTranslation() === PieceUnitP; 
    },

    /**
     * Gets piece weights based on total of allowances.
     *
     * @returns {Array} - The piece weights.
     */
    getPieceWeights() {
      let pieceWeights = [];
      this.getBaggage().forEach((bag) => {
        for (let i = 0; i < bag.allowance; i++) {
          pieceWeights.push(bag.pieceWeight);
        }
      });

      pieceWeights.sort((a, b) => {
        return b - a;
      });

      return pieceWeights;
    },

    /**
     * Get the Passenger ID of a passenger.
     *
     * Uses the latest version of the passenger from the store
     * based on passengerIndex. Which is relevant after plusgrade upgrade,
     * or other operations that change the passengerID.
     *
     * When flightNumber is provided will use the passengerID from the
     * passenger's baggageRoute structure, otherwise use the passengerID from
     * the passenger object.
     *
     * Also uses the passenger object's passengerID when the supplied
     * flightNumber matches the root flightNumber from the booking response,
     * as this value is deemed more reliable than the one in the
     * baggageRoute structure.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @param {string} flightNumber - The flight number (optional).
     * @returns {string} The passenger id.
     */
    getPassengerID(passenger, flightNumber = null) {
      const rootFlightNumber = get(this).flightNumber;
      if (passenger.passengerIndex) {
        passenger = this.passengerByIndex(passenger.passengerIndex);
      }
      if (flightNumber === null || flightNumber === rootFlightNumber) {
        return passenger.passengerID;
      }
      return getBaggageRoutePassengerId(passenger, flightNumber);
    },

    /**
     * Get Passenger gender from passenger docs.
     *
     * Defaults to M. Needs to use the MRZ of the passenger in the case
     * of a passenger without DocS in the booking.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {string}
     */
    getPassengerGender(passenger) {
      return passengerMrzManager.getPassengerGender(passenger) || '';
    },

    /**
     * Get Passenger nationality from passenger docS.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {string} i.e. 'IND'
     */
    getPassengerNationality(passenger) {
      return passengerMrzManager.getPassengerNationality(passenger) || '';
    },

    /**
     * Get passenger passport number from the passengers MRZ.
     *
     * @param {object} passenger - Passenger object.
     * @returns {string} passport number.
     */
    getPassengerMrzPassportNumber(passenger) {
      return passengerMrzManager.getPassengerPassportNumber(passenger);
    },

    /**
     * Get Passenger nationality from passenger docs.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {string} i.e. 'IND'
     */
    getPassengerNationality(passenger) {
      return passengerMrzManager.getPassengerNationality(passenger) || '';
    },

    /**
     * Build a log fragment for a passenger.
     *
     * @param {object} passenger - A switchboard passenger object.
     * @returns {string} The log frgament, serialising the passenger.
     */
    getPassengerLogMessage(passenger) {
      return passenger
        ? `passenger: ${passenger.firstName} ${passenger.lastName} ` +
        `(passengerID: ${passenger.passengerID}; ` +
        `passengerIndex: ${passenger.passengerIndex})`
        : '';
    },

    /**
     * Parse a passengers docS String.
     *
     * `passenger` A passenger from the booking response.
     * Returns an object containing parsed fields.
     */
    parsePassengerDocString(passenger) {
      let { passportIssuingCountry, passportNumber, gender } = passenger;
      if (!passportNumber) {
        return {};
      }

      return { passportNumber, passportIssuingCountry, gender };
    },

    

    /**
     * Modify booking.passengers; remove all infants and store them separately.
     */
    extractInfants() {
      const { passengers } = get(this);
      const [infantPassengers, adultPassengers] = passengers.reduce(
        ([infantsFound, adultsFound], passenger) =>
          passenger.passengerType === INFANT_PASSENGER_TYPE
            ? [[...infantsFound, passenger], adultsFound]
            : [infantsFound, [...adultsFound, passenger]],
        [[], []],
      );
      this.set({ ...get(this), passengers: adultPassengers });
      infants.set(
        infantPassengers.map((infant) => {
          const {
            frequentFlyerInformation,
            passengerBaggageRoutes,
            specialServiceRequests,
          } = booking.getInfantParent(infant);

          return {
            ...infant,
            frequentFlyerInformation,
            passengerBaggageRoutes,
            specialServiceRequests,
          };
        }),
      );
    },

    /**
     * Enriches the passenger array with a sequential index.
     */
    indexPassengers() {
      const oldBooking = get(this);
      
      // we are going to index passengers based on Adult first
      // because sometimes amadeus returns an infant as first passenger
      // and we do not want to give PassengerIndex 0 to infant.
      // therefore we start with adult and then add its infant
      let adultsList = [];
      let infantsList = [];
      let finalPassengersList = [];
      let index = 0;
      
      // this will index all the adult passenger
      oldBooking.passengers.forEach((passenger) => {
        if (passenger.passengerType === 'INFANT') {
          infantsList.push(passenger);
        } else {
          adultsList.push(passenger);
        }
      });

      if (adultsList && adultsList.length > 0) {
        adultsList.forEach((adult) => {
          adult.passengerIndex = index;
          finalPassengersList.push(adult);
          index = index + 1;

          let infant = infantsList.filter(x => x.passengerID === adult.infantPassengerID);
          if (infant && infant.length > 0) {
            infant[0].passengerIndex = index;
            finalPassengersList.push(infant[0]);
            index = index + 1;
          }
        });
      }

      this.set({
        ...oldBooking,
        passengers: finalPassengersList
      });
    },

    /**
     * Overwrite the passengers array in the booking store.
     *
     * Will index passengers and extract infants into the infant store.
     *
     * @param {object[]} passengers - Array of Switchboard Passenger objects.
     */
    setPassengers(passengers) {
      const oldPassengers = get(this).passengers;

      this.set({ ...get(this), passengers });
      this.indexPassengers();
      this.extractInfants();
      checkInPassengersManager.refreshPassengers(this);

      function passengersLogMessage(passengers) {
        return passengers
          .map(
            (passenger) =>
              `${passenger.firstName}, ${passenger.lastName}, ` +
              `index: ${passenger.passengerIndex}, ` +
              `ID: ${passenger.passengerID}, ` +
              `nameId: ${passenger.nameRefNumber}, ` +
              `ticket number: ${passenger.ticketNumber}`,
          )
          .join('; ');
      }

      logger.info(
        `Overwrote passengers. ` +
        `Old passengers: ${passengersLogMessage(oldPassengers)}. ` +
        `New passengers: ${passengersLogMessage(get(this).passengers)}.`,
      );
    },

    /**
     * Update the booking store with a new booking object.
     *
     * @param {object} newBookingObject - A Switchboard Booking object.
     */
    updateBookingObject(newBookingObject) {
      const previousBookingObject = get(this);
      const separatedKeys = ['passengers'];
      const updates = {};

      for (const [key, value] of Object.entries(newBookingObject)) {
        if (separatedKeys.includes(key)) {
          continue;
        }
        if (newBookingObject[key] !== previousBookingObject[key]) {
          updates[key] = value;
        }
      }

      booking.set({ ...previousBookingObject, ...updates });
      logger.info(
        `Updated the following booking fields, ` +
        `${updates && Object.keys(updates).join(', ')}.`,
      );

      booking.setPassengers(newBookingObject.passengers);
    },

    /**
     * Overwrite the segements array in the booking store.
     *
     * @param {object[]} segments - Array of Switchboard Segment objects.
     */
    setSegments(segments) {
      this.set({ ...get(this), segments });
    },

    /**
     * Make necessary adjustments for passenger who initiated the transaction.
     *
     * Note that the docS passport number is ignored. This is allowable as
     * EYSS-2393 requires that docS is ignored for non-checked-in passengers.
     */
    repairForInitialPassportScan() {
      if (get(userInitiationMethod) === TransactionInitiator.PASSPORT) {
        const passenger = this.getPassengerWhoInitiatedTransaction();
        logger.info(
          `Storing MRZ for passenger who initiated the transaction, ` +
          `passenger ID: ${passenger && passenger.passengerID}.`,
        );
        passengerMrzManager.addPassengerMrz(passenger, get(mrz));

        if (!checkInPassengersManager.isCheckInPassenger(passenger)) {
          logger.info(
            `Addding passenger who initiated the transaction to the list ` +
            `of passengers who will be checked in, ` +
            `passenger ID: ${passenger && passenger.passengerID}.`,
          );
          checkInPassengersManager.addCheckInPassenger(
            passenger,
            this.getPassengerMrzPassportNumber(passenger),
          );
        }
      }
    },

    /**
     * The passenger whose DocS matches the MRZ used for booking retrieval.
     *
     * @returns {object} The Switchboard passenger object.
     */
    getPassengerWhoInitiatedTransaction() {
      return get(mrz).length
        ? this.passengerMatchingDocSPassportNumber(
          mrzParser.parse(get(mrz)).fields.documentNumber,
        )
        : {};
    },

    /**
     * Get new passenger list from full passenger list.
     * @param {string} type Pass passenger type "STANDARD","INFANT"
     */
    extractPassengersByType(type) {
      const { passengers } = get(this);
      const passengerByType = passengers.filter(
        (data) => data.passengerType === type,
      );
      return passengerByType;
    },

    /**
     * Return total allowance by type for pool.
     *
     * @param {string} baggageType is type of String
     * @returns {number} value - The total number of the baggageType.
     */
    totalAllowanceByType(baggageType = null) {
      let totalAllowance = 0;
      const { passengers } = get(this);

      if (passengers) {
        totalAllowance = passengers
          .concat(get(infants))
          .reduce(
            (total, passenger) =>
              total +
              Number(booking.passengerAllowanceByType(passenger, baggageType)),
            0,
          );
      }

      return totalAllowance;
    },
    
    totalAllowanceByTypeWithoutFF() {
      let totalAllowance = 0;
      
      const { passengers } = get(this);
      const adultsAndInfants = (passengers || []).concat(get(infants));

      try {
        adultsAndInfants.forEach(p => {
          const { baggage } = p;
          if (baggage && baggage.length > 0) {
            baggage.forEach(b => {
              if (b.type !== AllowanceType.FF) {
                totalAllowance = totalAllowance + parseInt(b.allowance);
              } 
            })
          }
        });
        return totalAllowance;
      } catch {
        return 0;
      }
    },

    totalFrequentFlyerAllowance() {
      let totalFFAllowance = 0;
      
      const { passengers } = get(this);
      const adultsAndInfants = (passengers || []).concat(get(infants));

      try {
        adultsAndInfants.forEach(p => {
          const { baggage } = p;
          if (baggage && baggage.length > 0) {
            baggage.forEach(b => {
              if (b.type === AllowanceType.FF) {
                totalFFAllowance = totalFFAllowance + parseInt(b.allowance);
              } 
            })
          }
        });
        return totalFFAllowance;
      } catch {
        return 0;
      }
    },

    passengerAllowanceByTypeWithoutFF(passenger) {
      const { baggage } = passenger;
      const ignoreType = !baggageType;

      return (baggage || [])
        .filter(
          (baggageItem) => ignoreType || baggageItem.type === baggageType,
        )
        .reduce((allowance, baggageItem) => {
          return (allowance += Number(baggageItem.allowance) || 0);
        }, 0);
    },


    /**
     * Return total allowance for pool
     */
    totalCombinedAllowance() {
      return Number(this.totalAllowanceByType());
    },

    /**
     * Return total allowance for pool
    */
    totalRemainingAllowance() {
      return Number(this.totalAllowanceByType()) - this.totalWeightUsedInPreviousTransaction();
    },

    totalNumberOfIssuedBagTags() {
      return Number(get(this).totalNumberOfIssuedBagTags);
    },

    totalNumberOfActivatedBagTags() {
      return Number(get(this).activatedBagTagsList.length);
    },
    /** Total weight consumed in previous transactions
     *  please read (see documentation/return-to-bag-drop.readme.md)
     */
    totalWeightUsedInPreviousTransaction() {
      let weightConsumed = 0;
      const currentBooking = get(this);
      
      if (currentBooking && currentBooking.baggageReference && currentBooking.isBaggageInfoForScannedPassenger) {
        if (currentBooking.activatedBagTagsList && currentBooking.activatedBagTagsList?.length > 0) {    
          currentBooking.activatedBagTagsList?.forEach(b => { weightConsumed = weightConsumed + parseInt(b.bagWeight)});
        }
      }
      return weightConsumed;
    },

    /**
     * Whether or not the flight is running on time.
     *
     * Checks that the scheduled and estimated departure
     * dates and times match exactly.
     * @returns {boolean} Whether or not the flight is on time.
     */
    isOnTime() {
      const {
        departureDate,
        scheduledDepartureDate,
        departureTime,
        scheduledDepartureTime,
      } = get(this);
      if (
        departureDate === scheduledDepartureDate &&
        departureTime === scheduledDepartureTime
      ) {
        return true;
      }
      return false;
    },

    /**
     * Return an infant's parent.
     *
     * If the parameter is already a parent, return it back.
     * @param {object} passenger - Switchboard passenger object.
     * @returns {object} - The parent, or the same passenger that was passed in.
     */
    getInfantParent(passenger) {
      return this.passengerByID(passenger.passengerID);
    },

    /**
     * Get Passenger Seat Number from passenger.
     *
     * Assumes first flight segment.
     * Will consider seats assigned during check-in.
     * In case of infant, returns "N/A".
     *
     * @param {object} passenger - Switchboard passenger object.
     * @param {string} flightNumber - The flight number.
     * @returns {string}
     */
    getSeatNumber(passenger, flightNumber = null) {
      if (passenger?.passengerType === 'INFANT') {
        return 'N/A';
      }

      flightNumber = flightNumber || get(this).flightNumber;

      let seatNumber = 'N/A';
      if (passenger && passenger?.segments && passenger?.segments?.length > 0) {
        passenger.segments.map((segment) => {
          if (segment.flightNumber === flightNumber) {
            seatNumber = segment.seatNumber
          }
        });
      }

      return seatNumber;
    },

    /**
     * Does the booking have checked-in passengers without a valid DocS?
     *
     * For the purpose of this function, a valid DocS
     * contains a passport number.
     *
     * @returns {boolean}
     */
    hasCheckedInWithoutValidDocS() {
      return this.getPassengersIncludingInfants().some(
        (passenger) =>
          !booking.passengerDocSPassportNumber(passenger) &&
          passenger.isCheckedIn,
      );
    },

    /**
     * Does the booking have a passenger with Plusgrade?
     *
     * Note: Business class check is temporary since we are only showing Cabin Upgrade for now.
     *
     * @param {object} passenger - Switchboard passenger object.
     * @returns {boolean}
     */
    hasPlusgradeUpgrade(passenger) {
      const isEconomyClass = FARE_CODE_MAP['Economy'].includes(
        this.getBookingCode(passenger),
      );

      return (
        get(this).passengers.some((passenger) =>
          (passenger.baggage || []).some((baggage) => baggage.type === 'PGCU'),
        ) || !isEconomyClass
      );
    },

    /**
     * Mutates the booking to randomise passenger IDs.
     *
     * This can be used for debugging purposes.
     */
    randomisePassengerIds() {
      this.set({
        ...get(booking),
        passengers: get(booking).passengers.map((passenger) => {
          return { ...passenger, passengerID: randomString(6) };
        }),
      });
    },

    /**
     * Get journey segments to display.
     *
     * @param {boolean} excludeCurrent - Whether to exclude the current flight.
     * @returns {object[]} Array of Switchboard Segment objects.
     */
    getDisplayedSegments(
      excludeCurrent = true,
      segments = null,
      shortCheckInDestiation = null,
    ) {
      segments ||= get(booking).segments;
      const segmentFilter = new DisplayedSegmentsFilter(
        segments,
        baseStationCode,
        excludeCurrent,
        shortCheckInDestiation,
      );
      return segmentFilter
        .sortByDateTime()
        .removeInward()
        .removeReturn()
        .removePostStopOver()
        .removeCurrent()
        .filterShortCheckIn().segments;
    },

    /**
     * This should return operatingFlightNumber,
     *
     * @param {string} flightNumber - flight number.
     */
    getDisplayedFlightNumber(flightNumber = null) {
      if (!flightNumber) {
        flightNumber = get(this).flightNumber;
      }
      const segments = get(this).segments;
      return (
        segments.find((segment) => segment.flightNumber === flightNumber) || {}
      ).flightNumber;
    },

    /**
     * Returns concatenation of airline code and flight number.
     *
     * @param {string} flightNumber - flight number
     * @returns {string} The concatenated code.
     */
    getDisplayedFlightCode(flightNumber = null) {
      return (
        `${this.getAirlineCode()} ` +
        `${stripLeadingZeroes(this.getDisplayedFlightNumber())}`
      );
    },

    // this method is just for a case
    // where transaction is started with MRZ scan
    // and its a single passenger transaction
    getSinglePassenger() {
      const { passengers } = get(this);
      if (passengers && passengers.length > 0) {
        return passengers[0];
      }
    },
  },
};

export const flightDate = derived([booking, locale], ([$booking, $locale]) =>
  ddMmmYYYY(compensateForTimezone($booking.departureDate), $locale),
);

export const departureTime = derived(booking, ($booking) =>
  time($booking.departureDateTime),
);

export const arrivalTime = derived(booking, ($booking) =>
  time($booking.arrivalDateTime),
);

/** Added weight paid for by the user. */
export const paidAllowance = resettable(0);

/** Whether or not the booking is retrieved. */
export const bookingRetrieved = resettable(false);
